import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Alert,
  MenuItem,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/SaveAs";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestimonialAsync,
  updateTestimonialAsync,
  setContent,
  setName,
  setTitle,
  testimonialState,
  setLanguage,
} from "../reduxSlice";

const DropdownList = [
  {
    value: "Kannada",
    label: "Kannada",
  },
  {
    value: "English",
    label: "English",
  },
];

export default function TestimonialForm(props) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const { id, title, name, content } = useSelector(testimonialState);
  const handleClick = (e) => {
    e.preventDefault();
    if (title && name && content) {
      dispatch(createTestimonialAsync({}));
      props.handleClose();
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleEdit = (e) => {
    e.preventDefault();
    if (title && name && content) {
      dispatch(updateTestimonialAsync({}));
      props.handleClose();
      setError(false);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <Box className="w-100">
        <Grid container spacing={2} component="form">
          <Grid item xs={12} className="d-flex justify-content-end">
            <IconButton onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {error ? (
            <Grid item xs={12} className="px-0">
              <Alert severity="error">
                Please fill out all required fields
              </Alert>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={12}>
            <TextField
              id="outlined-select-currency"
              select
              label="Select Language"
              defaultValue=""
              className="w-100"
              required
              onChange={(e) => {
                dispatch(setLanguage({ value: e.target.value }));
              }}
            >
              {DropdownList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Title"
              required
              placeholder="Title for testimonial"
              variant="outlined"
              className="w-100"
              defaultValue={id ? title : ""}
              onChange={(e) =>
                dispatch(
                  setTitle({
                    title: e.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Name"
              defaultValue={id ? name : ""}
              required
              placeholder="- John Mark"
              variant="outlined"
              className="w-100"
              onChange={(e) =>
                dispatch(
                  setName({
                    name: e.target.value,
                  })
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Content"
              defaultValue={id ? content : ""}
              required
              variant="outlined"
              className="w-100"
              multiline
              rows={3}
              onChange={(e) =>
                dispatch(
                  setContent({
                    content: e.target.value,
                  })
                )
              }
            />
          </Grid>

          <Grid item xs={12} className="d-flex justify-content-end px-0">
            {id ? (
              <Button
                variant="contained"
                type="button"
                startIcon={<SaveIcon />}
                onClick={handleEdit}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
                onClick={handleClick}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
