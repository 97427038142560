import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createVideoGallery, getVideoGallery } from "./reduxAPI";

const initialState = {
  id: "",
  videoLink: "",
  collection: [],
  loading: false,
  website: "stanthony",
};

export const videoLinkAsync = createAsyncThunk(
  "videoGallery/videoLink",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { videoLink, website } = getState().videoGallery;
      const response = await createVideoGallery(videoLink, website);
      dispatch(clearState({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const getvideoGalleryAsync = createAsyncThunk(
  "videoGallery/getVideoGallery",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = "stanthony";
      const { website } = getState().videoGallery;
      const response = await getVideoGallery(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const videoGallerySlice = createSlice({
  name: "videoGallery",
  initialState,
  reducers: {
    setVideoLink: (state, action) => {
      state.videoLink = action.payload.value;
    },
    clearState: (state) => {
      state.videoLink = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(videoLinkAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(videoLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.videoLink = "";
      })
      .addCase(videoLinkAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getvideoGalleryAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = [];
      })
      .addCase(getvideoGalleryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload;
        // action.payload.data.forEach((link)=>{
        //   state.youtubeCollection = [...state.youtubeCollection , link].sort(function (a, b) {
        //     return new Date(b.date) - new Date(a.date);
        //   });
        // })
        // state.todaysLink = state.youtubeCollection[0].youtubeLink
      })
      .addCase(getvideoGalleryAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
export const { setVideoLink, clearState } = videoGallerySlice.actions;
export const videoGalleryState = (state) => state.videoGallery;
export default videoGallerySlice.reducer;
