import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createYoutube, getYoutubeLink } from "./reduxAPI";
const initialState = {
  youtubeLink : "",
  youtubeCollection: [],
  todaysLink: "",
  loading: false,
  website:"stanthony",
};

export const youtubeLinkAsync = createAsyncThunk(
  "youtube/youtubeLink",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { youtubeLink,website } = getState().youtube;
      const response = await createYoutube(youtubeLink,website);
      dispatch(clearState({}))
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getYoutubeLinkAsync = createAsyncThunk(
  "youtube/getYoutubeLink",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken")
      const { website } = getState().youtube;
      const response =await getYoutubeLink(sessionToken,website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {
    setYoutubeLink: (state, action) => {
      state.youtubeLink = action.payload.value;
    },
    clearState: (state)=>{
      state.youtubeLink = ""
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(youtubeLinkAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(youtubeLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.youtubeLink = ""
      })
      .addCase(youtubeLinkAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getYoutubeLinkAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getYoutubeLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.youtubeCollection = action.payload
        // action.payload.data.forEach((link)=>{
        //   state.youtubeCollection = [...state.youtubeCollection , link].sort(function (a, b) {
        //     return new Date(b.date) - new Date(a.date);
        //   });
        // })
        // state.todaysLink = state.youtubeCollection[0].youtubeLink
      })
      .addCase(getYoutubeLinkAsync.rejected, (state, action) => {
        state.loading = false;
      })

  },
});

export const { setYoutubeLink ,clearState} =
  youtubeSlice.actions;
export const youtubeState = (state) => state.youtube;
export default youtubeSlice.reducer;
