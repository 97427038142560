import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createTestimonial(title, name, content, website, language) {
  const createDoc = httpsCallable(functions, "createTestimonial");
  return createDoc({ title, name, content, website, language });
}

export function getTestimonial(sessionToken, website) {
  const getDoc = httpsCallable(functions, "getTestimonial");
  return getDoc({ sessionToken, website });
}

export function deleteTestimonial(id, website) {
  const deleteDoc = httpsCallable(functions, "deleteTestimonial");
  return deleteDoc({ id, website });
}

export function updateTestimonial(id, title, name, content, website, language) {
  const updateDoc = httpsCallable(functions, "updateTestimonial");
  return updateDoc({ id, title, name, content, website, language });
}
