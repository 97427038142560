import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// icon
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { fromByteArray } from "base64-js";
import {
  littlevisitorState,
  uploadFileAsync,
  setTitle,
  getFolderCollectionAsync,
} from "../reduxSlice";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function PdfList() {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [fileType, setFileType] = React.useState("");
  const dispatch = useDispatch();
  const { selectedHeading, folderCollection, loading } =
    useSelector(littlevisitorState);

  React.useEffect(() => {
    dispatch(getFolderCollectionAsync({}));
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setFileName(file.name);
    setFileType(file.type);
    reader.onload = (event) => {
      const fileData = event.target.result;
      const base64String = fromByteArray(new Uint8Array(fileData));
      setFile(base64String);
    };

    reader.readAsArrayBuffer(file);
  };

  // if (!folderCollection === "") {
  //   var sortedArr = folderCollection.sort(function (a, b) {
  //     return new Date(b.uploadDate) - new Date(a.uploadDate);
  //   });
  // }
  // console.log(sortedArr);
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {selectedHeading}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: "grey",
              ml: "auto",
              mr: 3,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          ></Divider>
          <Tooltip title="Add new row" arrow placement="top">
            <Button
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}
              className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
              onClick={handleOpen}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={60} />
          ) : (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow
                    sx={{ background: "#000", textTransform: "capitalize" }}
                  >
                    <TableCell sx={{ color: "#fff" }} align="left">
                      Sr. No.
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="left">
                      File Name
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="center">
                      Created Date
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }} align="right">
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {folderCollection &&
                    folderCollection.map((row, i) => {
                      return (
                        <StyledTableRow
                          className="shadow-sm "
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell align="left">
                            {i + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.title}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.uploadDate.substring(0, 34)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton>
                              <DeleteIcon
                                sx={{ color: "#5D5D5D", cursor: "pointer" }}
                              />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="d-flex justify-content-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                variant="outlined"
                name="fileName"
                label="Title"
                placeholder="Little Visitor January 2023"
                helperText="Title : Little Visitor January 2023"
                className="w-100"
                onChange={(e) => dispatch(setTitle({ value: e.target.value }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputProps={{
                  accept: ".pdf",
                }}
                id="outlined-basic"
                type="file"
                variant="outlined"
                className="w-100"
                onChange={onSelectFile}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className="px-md-5"
                type="submit"
                onClick={(e) => {
                  dispatch(uploadFileAsync({ file, fileName, fileType }));
                  handleClose();
                  dispatch(getFolderCollectionAsync({}));
                }}
              >
                {"Save"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
