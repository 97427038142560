import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import CloseIcon from "@mui/icons-material/Close";
import PdfList from "./PDF/PdfList";
import { useDispatch, useSelector } from "react-redux";
import {
  createFolderAsync,
  getCreateFolderAsync,
  littlevisitorState,
  setFolderName,
  setSelectedHeading,
  setShowPdfList,
  setYear,
} from "./reduxSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function MonthlyMagazine() {
  const dispatch = useDispatch();
  const { showPdfList, selectedHeading, collection, loading } =
    useSelector(littlevisitorState);

  const [open, setOpen] = React.useState(false);
  // const [showPdfList, setShowPdfList] = React.useState(false);

  React.useEffect(() => {
    dispatch(getCreateFolderAsync({}));
  }, [dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {showPdfList ? (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              variant="subtile2"
              sx={{
                color: "#1976d2",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline", color: "#1976d2" },
              }}
              onClick={(e) => dispatch(setShowPdfList({ value: false }))}
            >
              <ArrowBackIcon sx={{ mb: 0.5, mr: 1, fontSize: "18px" }} />
              Year List
            </Typography>
            <Typography variant="subtile1" component="p" color="text.primary">
              {selectedHeading}
            </Typography>
          </Breadcrumbs>
          <PdfList />
        </>
      ) : (
        <>
          <Container
            className="p-md-5"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: {
                  xs: 2,
                  md: 0,
                },
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" component="h5">
                {"Monthly Magazine"}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  background: "grey",
                  ml: "auto",
                  mr: 3,
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              ></Divider>
              <Tooltip title="Add new row" arrow placement="top">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<AddIcon />}
                  className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
                  onClick={handleOpen}
                >
                  Add
                </Button>
              </Tooltip>
            </Box>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={60} />
              ) : (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{ background: "#000", textTransform: "capitalize" }}
                      >
                        <TableCell sx={{ color: "#fff" }} align="left">
                          Folder Name
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="left">
                          Year
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Created Date
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Open Folder
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {collection
                        ? collection?.map((row, i) => (
                            <StyledTableRow
                              className="shadow-sm "
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell align="left">
                                {row.folderName}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.year}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.createdDate.substring(0, 34)}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <IconButton
                                  className="me-3"
                                  onClick={() => {
                                    dispatch(setShowPdfList({ value: true }));
                                    dispatch(
                                      setSelectedHeading({
                                        value: row.folderName,
                                      })
                                    );
                                    dispatch(setYear({ value: row.year }));
                                  }}
                                >
                                  <FolderOpenIcon
                                    sx={{ color: "#1976d2", cursor: "pointer" }}
                                  />
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <IconButton>
                                  <DeleteIcon
                                    sx={{ color: "#5D5D5D", cursor: "pointer" }}
                                  />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Container>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="d-flex justify-content-end">
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p" component="div" sx={{ mb: 1 }}>
                    Select Year
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year"]}
                      className="w-100"
                      onChange={(e) => {
                        const year = new Date(e).getFullYear();
                        dispatch(setYear({ value: year }));
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Folder Name"
                    placeholder="Little Visitor 2023"
                    variant="outlined"
                    className="w-100"
                    onChange={(e) =>
                      dispatch(setFolderName({ value: e.target.value }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className="px-md-5"
                    type="button"
                    onClick={() => {
                      dispatch(createFolderAsync({}));
                      handleClose();
                      dispatch(getCreateFolderAsync({}));
                    }}
                  >
                    {"  Save"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
