import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Skeleton,
  IconButton,
} from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AnnouncementForm from "./Form/AnnouncementForm";
import {
  announcementState,
  deleteAnnoucementAsync,
  getAnnouncementAsync,
  setFormModal,
  setID,
  setDeleteKey,
  updateAnnouncementAsync,
  setSelectedIndex,
  clearState,
} from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function App() {
  const dispatch = useDispatch();
  const { formModel, announcementCollection, loading } =
    useSelector(announcementState);

  React.useEffect(() => {
    dispatch(getAnnouncementAsync({}));
    return () => {
      dispatch(clearState({}));
    };
  }, [dispatch]);

  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {formModel ? (
          <>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                variant="subtile2"
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline", color: "#1976d2" },
                }}
                onClick={() => {
                  dispatch(setFormModal({}));
                }}
              >
                Announcement
              </Typography>
              <Typography variant="subtile1" component="p" color="text.primary">
                Announcement Form
              </Typography>
            </Breadcrumbs>
            <AnnouncementForm />
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: {
                  xs: 2,
                  md: 0,
                },
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" component="h5">
                {" Announcement"}
              </Typography>

              <Tooltip title="Add new row" arrow placement="top">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<AddIcon />}
                  className="py-2 px-md-5 rounded-1 fw-bold btn-blue"
                  onClick={() => {
                    dispatch(setFormModal({}));
                  }}
                >
                  Add
                </Button>
              </Tooltip>
            </Box>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={60} />
              ) : (
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#000",
                          textTransform: "capitalize",
                        }}
                      >
                        <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                        <TableCell sx={{ color: "#fff" }} align="center">
                          Title
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Created Date
                        </TableCell>
                        {/* <TableCell sx={{ color: "#fff" }} align="right">
                          Edit
                        </TableCell> */}
                        <TableCell sx={{ color: "#fff" }} align="right">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {announcementCollection?.map((row, i) => (
                        <StyledTableRow
                          className="shadow-sm "
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <img
                              src={row.imageURL.Location}
                              alt=".."
                              style={{ width: "100px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.heading}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.date}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">
                            <IconButton
                              onClick={() => {
                              }}
                            >
                              <CreateIcon
                                sx={{ color: "#1976d2", cursor: "pointer" }}
                              />
                            </IconButton>
                          </StyledTableCell> */}
                          <StyledTableCell align="right">
                            <IconButton
                              onClick={() => {
                                dispatch(setID({ value: row.id }));
                                dispatch(
                                  setDeleteKey({ value: row.imageURL.Key })
                                );
                                dispatch(deleteAnnoucementAsync({}));
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: "#5D5D5D", cursor: "pointer" }}
                              />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
