import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAnnouncement,
  getAnnouncement,
  deleteAnnoucement,
  updateAnnouncement,
} from "./reduxAPI";
import draftToHtml from "draftjs-to-html";
const initialState = {
  loading: "",
  id: "",
  selectedIndex: "",
  deleteKey: "",
  Buttonloading: false,
  heading: "",
  date: "",
  body: "",
  fileName: "",
  fileType: "",
  uploadFile: "",
  website: "stanthony",
  announcementCollection: [],
  formModel: false,
};

export const createAnnouncementAsync = createAsyncThunk(
  "announcement/createAnnouncement",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { heading, date, body, fileName, fileType, uploadFile, website } =
        getState().announcement;
      let content = draftToHtml(body);
      console.log(content);
      const response = await createAnnouncement(
        heading,
        date,
        content,
        fileName,
        fileType,
        uploadFile,
        website
      );
      dispatch(setFormModal({}));
      dispatch(clearState({}));
      dispatch(getAnnouncementAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getAnnouncementAsync = createAsyncThunk(
  "announcement/getAnnouncement",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken");
      const { website } = getState().announcement;
      const response = await getAnnouncement(sessionToken, website);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateAnnouncementAsync = createAsyncThunk(
  "announcement/updateAnnouncement",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        id,
        deleteKey,
        heading,
        date,
        body,
        uploadFile,
        fileName,
        fileType,
        website,
      } = getState().announcement;
      const response = await updateAnnouncement(
        id,
        deleteKey,
        heading,
        date,
        body,
        uploadFile,
        fileName,
        fileType,
        website
      );
      dispatch(getAnnouncementAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteAnnoucementAsync = createAsyncThunk(
  "announcement/deleteAnnouncement",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, deleteKey, website } = getState().announcement;
      const response = await deleteAnnoucement(id, deleteKey, website);
      dispatch(getAnnouncementAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.value;
    },
    setDeleteKey: (state, action) => {
      state.deleteKey = action.payload.value;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload.value;
    },
    setFormModal: (state, action) => {
      state.formModel = !state.formModel;
    },
    setHeading: (state, action) => {
      state.heading = action.payload.value;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setBody: (state, action) => {
      state.body = action.payload.value;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload.value;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload.value;
    },
    setUploadFile: (state, action) => {
      state.uploadFile = action.payload.value;
    },
    clearState: (state) => {
      state.body = "";
      state.heading = "";
      state.date = "";
      state.fileName = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAnnouncementAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(createAnnouncementAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(createAnnouncementAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(getAnnouncementAsync.pending, (state, action) => {
        state.loading = true;
        state.announcementCollection = [];
      })
      .addCase(getAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((data) => {
          state.announcementCollection = [
            ...state.announcementCollection,
            data,
          ].sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
        });
      })
      .addCase(getAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteAnnoucementAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteAnnoucementAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteAnnoucementAsync.rejected, (state, action) => {
        state.loading = false;
        state.deleteKey = "";
        state.id = "";
      })

      .addCase(updateAnnouncementAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
        state.deleteKey = "";
        state.id = "";
      });
  },
});

export const {
  setID,
  setDeleteKey,
  setSelectedIndex,
  setFormModal,
  setHeading,
  setDate,
  setBody,
  setFileName,
  setFileType,
  setUploadFile,
  clearState,
} = announcementSlice.actions;
export const announcementState = (state) => state.announcement;
export default announcementSlice.reducer;
