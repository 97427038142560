import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createYoutube(youtubeLink,website) {
  const createYoutubeDoc = httpsCallable(functions, "createYoutube");
  return createYoutubeDoc({youtubeLink,website});
}

export function getYoutubeLink(sessionToken,website) {
  const getYoutubeLinkDoc = httpsCallable(functions, "getLastYoutube");
  return getYoutubeLinkDoc({sessionToken,website});
}
