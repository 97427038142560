import { Box, Button, Container, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearState,
  getYoutubeLinkAsync,
  setYoutubeLink,
  youtubeLinkAsync,
  youtubeState,
} from "./reduxSlice";

export default function App() {
  const dispatch = useDispatch();
  const { youtubeLink } = useSelector(youtubeState);

  React.useEffect(() => {
    dispatch(getYoutubeLinkAsync({}));
    return () => {
      dispatch(clearState({}));
    };
  }, [dispatch]);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(youtubeLinkAsync({}));
  };
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {"Youtube Embedded"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="p" component="div" sx={{ my: 2 }}>
            Enter the youtube link :
          </Typography>
          <Box component="form" onSubmit={handleClick}>
            <TextField
              required
              placeholder="TkUlLVMslKc"
              id="youtubeLink"
              label="Link"
              fullWidth
              value={youtubeLink ? youtubeLink : ""}
              helperText="you will find link under : youtube vedio → share → embed → src='https://www.youtube.com/embed/TkUlLVMslKc'"
              className="mb-4"
              onChange={(e) => {
                dispatch(setYoutubeLink({ value: e.target.value }));
              }}
            />
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}
