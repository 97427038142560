import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createAnnouncement(
  heading,
  date,
  content,
  fileName,
  fileType,
  uploadFile,
  website
) {
  console.log(content);
  const createDoc = httpsCallable(functions, "createAnnoucement");

  return createDoc({
    heading,
    date,
    body: content,
    fileName,
    fileType,
    uploadFile,
    website,
  });
}

export function getAnnouncement(sessionToken, website) {
  const getDoc = httpsCallable(functions, "getAnnoucement");
  return getDoc({ sessionToken, website });
}

export function deleteAnnoucement(id, deleteKey, website) {
  const getDoc = httpsCallable(functions, "deleteAnnoucement");
  return getDoc({ id, deleteKey, website });
}

export function updateAnnouncement(
  id,
  deleteKey,
  heading,
  date,
  body,
  uploadFile,
  fileName,
  fileType,
  website
) {
  const getDoc = httpsCallable(functions, "updateAnnoucement");
  return getDoc({
    id,
    deleteKey,
    heading,
    date,
    body,
    uploadFile,
    fileName,
    fileType,
    website,
  });
}
