import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  setHeading,
  setDate,
  setBody,
  createAnnouncementAsync,
  announcementState,
} from "../reduxSlice";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Compressor from "compressorjs";
import { setFileName, setFileType, setUploadFile } from "../reduxSlice";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function AnnouncementForm() {
  const [img, setImg] = React.useState(
    "https://images.unsplash.com/photo-1679236630000-efdb716ed708?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
  );
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  const {
    Buttonloading,
    selectedIndex,
    announcementCollection,
    heading,
    date,
    body,
    fileName,
  } = useSelector(announcementState);
  const handleChange = async (event) => {
    const file = event.target.files[0];
    const preview = URL.createObjectURL(file);
    setImg(preview);
    dispatch(setFileName({ value: file.name }));
    dispatch(setFileType({ value: file.type }));

    new Compressor(file, {
      quality: 0.8,
      success: async (compressedResult) => {
        const convertedFile = await convertToBase64(compressedResult);
        dispatch(setUploadFile({ value: convertedFile }));
      },
    });

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };
  };
  const handleSubmit = () => {
    if (heading && date && body && fileName) {
      dispatch(createAnnouncementAsync({}));
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Grid item xs={12} md={6}>
          {error ? (
            <Alert severity="error">Please fill out all required fields</Alert>
          ) : (
            ""
          )}
          <Typography variant="p" component="div" sx={{ my: 1 }}>
            Enter the Announcement Heading
          </Typography>
          <TextField
            required
            id="outlined-required"
            fullWidth
            onChange={(e) => {
              dispatch(setHeading({ value: e.target.value }));
            }}
          />
          <Typography variant="p" component="div" sx={{ my: 1 }}>
            Select Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) => {
                dispatch(setDate({ value: e.toString() }));
              }}
            />
          </LocalizationProvider>
          <Typography variant="p" component="div" sx={{ my: 1 }}>
            {"Enter the Announcement Content"}
          </Typography>
          <Box
            sx={{
              minHeight: "250px",
              border: 2,
              borderColor: "#e9e9e9",
              px: 2,
              my: 2,
            }}
          >
            <MUIRichTextEditor
              label="Type something here..."
              controls={[
                "title",
                "bold",
                "italic",
                "underline",
                "undo",
                "redo",
                "numberList",
                "bulletList",
                "clear",
              ]}
              onChange={(state) => {
                let convertRaw = convertToRaw(state.getCurrentContent());
                dispatch(
                  setBody({
                    value: convertRaw,
                  })
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography variant="p" component="div" sx={{ my: 1 }}>
            {"Upload Image ( image ratio must be 4:3)"}
          </Typography>
          <Box
            sx={{
              border: 1,
              borderStyle: "dotted",
              borderRadius: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "auto",
              maxHeight: 500,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <IconButton
              color="black"
              aria-label="upload picture"
              component="label"
              sx={{
                position: "absolute",
                zIndex: "1000",
                width: "100%",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleChange}
                style={{ boxShadow: 0, backgroundColor: "none" }}
              />
              <CameraAltIcon />
              <Typography component="p" variant="div" fontSize="small">
                Upload
              </Typography>
            </IconButton>

            <img
              src={img}
              alt="preview"
              style={{
                width: "100%",
                zIndex: "0",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        {Buttonloading ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Save
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            className="px-md-5"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </Box>
    </>
  );
}
