import React from "react";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";

export default function JeppuForm(props) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} className="d-flex justify-content-end">
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-center">
          <Typography variant="h6" component="h6">
            St Anthony's Chapel, Jeepu
          </Typography>
        </Grid>
        <Grid item xs={12} className="d-flex ">
          <IconButton
            color="black"
            aria-label="upload picture"
            component="label"
            className="p-3 w-100"
            sx={{
              zIndex: "1000",
              borderRadius: 1,
              border: "1px solid grey",
            }}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              // onChange={handleChange}
              style={{ boxShadow: 0, backgroundColor: "none" }}
            />
            <CameraAltIcon className="me-2" />
            <Typography component="p" variant="div" fontSize="small">
              Upload Image
            </Typography>
          </IconButton>
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            label="Heading"
            placeholder="St Anthony's Chapel, Jeepu"
            id="heading"
            name="heading"
            variant="outlined"
            className="w-100"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="On Tuesday's"
            label="Sub-heading"
            id="subHeading"
            name="subHeading"
            variant="outlined"
            className="w-100"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="6.00 a.m. - Mass & St Anthony Devotion"
            label="Early Morning Timing"
            id="earlyMorningTiming"
            name="earlyMorningTiming"
            variant="outlined"
            className="w-100"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="10.30 a.m. - Adoration, Mass & Devotion"
            label="Morning Timing"
            id="morningTiming"
            name="morningTiming"
            variant="outlined"
            className="w-100"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            placeholder="1.00 p.m. - Lunch for Devotees"
            label="Lunch Timing"
            id="lunchTiming"
            name="lunchTiming"
            variant="outlined"
            className="w-100"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
