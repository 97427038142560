import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../components/Components/Dashboard/reduxSlice";
import loginReducer from "../components/Components/Login/reduxSlice";
import newUserReducer from "../components/Components/User/reduxSlice";
import profileReducer from "../components/Components/Profile/reduxSlice";
import youtubeReducer from "../components/Components/YoutubeEmbedded/reduxSlice";
import dailyQuoteReducer from "../components/Components/DailyQuote/reduxSlice";
import galleryReducer from "../components/Components/Gallery/reduxSlice";
import announcementReducer from "../components/Components/Annoucement/reduxSlice";
import newsReducer from "../components/Components/News/reduxSlice";
import testimonialReducer from "../components/Components/Testimonial/reduxSlice";
import eventReducer from "../components/Components/Events/reduxSlice";
import videoGalleryReducer from "../components/Components/Video/reduxSlice";
import littleVisitorReducer from "../components/Components/MonthlyMagazine/reduxSlice";

const reducers = combineReducers({
  dashboard: dashboardReducer,
  login: loginReducer,
  newUser: newUserReducer,
  profile: profileReducer,
  youtube: youtubeReducer,
  dailyQuote: dailyQuoteReducer,
  gallery: galleryReducer,
  announcement: announcementReducer,
  news: newsReducer,
  testimonial: testimonialReducer,
  events: eventReducer,
  videoGallery: videoGalleryReducer,
  littleVisitor: littleVisitorReducer,
});

export const store = configureStore({
  reducer: reducers,
});
