import React from "react";
import {
  Box,
  Container,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import DevotionForm from "./DevotionForm";
import JeppuForm from "./Form/JeppuForm";
import MilagresForm from "./Form/MilagresForm";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    lg: "auto",
  },
  bgcolor: "background.paper",
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(sr, title, date) {
  return { sr, title, date };
}

const rows = [
  createData("1", "St Anthony's Shrine, Milagres", "15-feb-2023"),
  createData("2", "St Anthony's Chapel, Jeepu", "12-feb-2023"),
];

export default function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
    console.log(e.currentTarget); //table data
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Typography variant="h4" component="h5">
          {" Devotion Timings"}
        </Typography>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow
                  sx={{ background: "#000", textTransform: "capitalize" }}
                >
                  <TableCell sx={{ color: "#fff" }}>Sr.no</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                  <TableCell sx={{ color: "#fff" }} align="center">
                    Title
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Updated On
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Edit
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow
                    className="shadow-sm "
                    key={row.sr}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="left">{row.sr}</StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <img
                        src="https://images.unsplash.com/photo-1555696958-c5049b866f6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                        alt=".."
                        style={{ width: "50px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.date}</StyledTableCell>
                    <StyledTableCell align="right" onClick={handleOpen}>
                      <CreateIcon
                        sx={{ color: "#1976d2", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <DeleteIcon
                        sx={{ color: "#5D5D5D", cursor: "pointer" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {/* <DevotionForm /> */}
          <JeppuForm handleClose={handleClose} />
          {/* <MilagresForm handleClose={handleClose} /> */}
        </Box>
      </Modal>
    </>
  );
}
