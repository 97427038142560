import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function createDailyQuote(dailyQuote,reference,website) {
  const createDailyQuoteDoc = httpsCallable(functions, "createDailyQuote");
  return createDailyQuoteDoc({dailyQuote,reference,website});
}

export function getDailyQuote(sessionToken,website) {
  const getDailyQuoteDoc = httpsCallable(functions, "getDailyQuote");
  return getDailyQuoteDoc({sessionToken,website});
}
