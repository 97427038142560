import {
  Box,
  Button,
  Container,
  Divider,
  Tooltip,
  Typography,
  Modal,
  Tab,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { clearFields, getGalleryAsync, setAwsFolder } from "./reduxSlice";
import { useDispatch } from "react-redux";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import GalleryForm from "./Form/GalleryForm";
import GalleryList from "./components/GalleryList";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function Gallery() {
  const [value, setValue] = React.useState("gallery");
  const [label, setLabel] = React.useState("Gallery");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event, newValue) => {
    let folder = event.target.innerText.toLowerCase();
    setValue(newValue);
    setLabel(folder);
    dispatch(setAwsFolder({ value: newValue }));
    dispatch(getGalleryAsync({}));
  };

  React.useEffect(() => {
    dispatch(getGalleryAsync({}));
    return () => {
      dispatch(clearFields({}));
    };
  }, [dispatch]);
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {/* heading */}
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            component="h5"
            sx={{ textTransform: "capitalize !important" }}
          >
            {/* {" Gallery"} */}
            {label}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: "grey",
              ml: "auto",
              mr: 3,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          ></Divider>
          <Tooltip title="Add new row" arrow placement="top">
            <Button
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}
              onClick={handleOpen}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        {/* tabs */}
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: { xs: 320, sm: "100%" },
            }}
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Gallery" value="gallery" />
              <Tab label="History" value="history" />
              {/* <Tab label="Houses" value="houses" /> */}
              {/* <Tab label="Psychaitric Home" value="psychaitric_home" /> */}
              {/* <Tab label="Poor Students Home" value="poor_students_home" /> */}
              {/* <Tab label="Sambhrama Hall" value="sambhrama_hall" /> */}
              {/* <Tab label="Medical Care" value="medical_care" /> */}
              <Tab label="Spiritual Activities" value="spiritual_activities" />
              {/* <Tab label="Daily Chores" value="daily_chores" /> */}
              {/* <Tab label="Museum" value="museum" /> */}
              <Tab label="Shrine" value="shrine" />
            </TabList>
          </Box>
          <TabPanel value="gallery">
            <GalleryList />
          </TabPanel>
          <TabPanel value="history">
            <GalleryList />
          </TabPanel>
          <TabPanel value="houses">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="psychaitric_home">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="poor_students_home">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="sambhrama_hall">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="medical_care">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="spiritual_activities">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="daily_chores">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="museum">
            {" "}
            <GalleryList />
          </TabPanel>
          <TabPanel value="shrine">
            {" "}
            <GalleryList />
          </TabPanel>
        </TabContext>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <GalleryForm handleClose={handleClose} />
          </Box>
        </Modal>
      </Container>
    </>
  );
}
