import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TestimonialForm from "./Form/TestimonialForm";
import moment from "moment/moment";
import {
  getTestimonialAsync,
  testimonialState,
  setId,
  setTitle,
  setContent,
  setName,
  clearState,
  deleteTestimonialAsync,
} from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(0, 171, 228, 0.04)",
    // fontWeight: "bold",
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 375,
  maxWidth: 450,
  bgcolor: "background.paper",
  m: 0,
  border: 0,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function Testimonial() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { testimonialCollection } = useSelector(testimonialState);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    dispatch(getTestimonialAsync({}));
    return () => {
      dispatch(clearState({}));
    };
  }, [dispatch]);

  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {" Testimonial"}
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              background: "grey",
              ml: "auto",
              mr: 3,
              display: {
                xs: "none",
                md: "block",
              },
            }}
          ></Divider>
          <Tooltip title="Add new row" arrow placement="top">
            <Button
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}
              className="py-2 px-md-5 rounded-1 fw-bold btn-blue "
              onClick={() => {
                handleOpen();
                dispatch(setId({ value: "" }));
              }}
            >
              Add
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow
                  sx={{ background: "#000", textTransform: "capitalize" }}
                >
                  <TableCell sx={{ color: "#fff" }}>Title</TableCell>
                  <TableCell sx={{ color: "#fff" }} align="left">
                    Name
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="left">
                    Create
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Edit
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }} align="right">
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testimonialCollection?.map((row, i) => (
                  <StyledTableRow
                    className="shadow-sm "
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell>{row.title}</StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row.create).calendar()}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={() => {
                          dispatch(setId({ value: row.id }));
                          dispatch(
                            setTitle({
                              title: row.title,
                            })
                          );
                          dispatch(
                            setName({
                              name: row.name,
                            })
                          );
                          dispatch(
                            setContent({
                              content: row.content,
                            })
                          );
                          handleOpen();
                        }}
                      >
                        <CreateIcon
                          sx={{ color: "#1976d2", cursor: "pointer" }}
                        />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={() => {
                          dispatch(setId({ value: row.id }));
                          dispatch(deleteTestimonialAsync({}));
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: "#5D5D5D", cursor: "pointer" }}
                        />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <TestimonialForm handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
}
