import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  assignRole,
  deleteUser,
  getUserDetails,
  getUserProfile,
  updateUserPermissions,
} from "./reduxAPI";
const initialState = {
  email: "",
  password: "",
  role: "Member",
  loading: "",
  firstName: "",
  lastName: "",
  userCollection: [],
  permissions: [],
  UID: "",
  selectUser:"",
  website: "stanthony",
  Buttonloading: false,
  modalOpen: false,
};

export const assignRoleAsync = createAsyncThunk(
  "newUser/assignRole",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { email, password, permissions, firstName, lastName, website } =
        getState().newUser;
      const response = await assignRole(
        email,
        password,
        permissions,
        firstName,
        lastName,
        website
      );
      dispatch(setModalOpen({}));
      dispatch(clearState({}));
      dispatch(getUserProfileAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getUserProfileAsync = createAsyncThunk(
  "newUser/getUserProfile",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken");
      const { website } = getState().newUser;
      const response = await getUserProfile(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const getUserDetailsAsync = createAsyncThunk(
  "newUser/getUserDetails",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const UID = window.localStorage.getItem("sessionToken");
      const { website } = getState().newUser;
      const response = await getUserDetails(UID, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateUserPermissionsAsync = createAsyncThunk(
  "newUser/updateUserPermissions",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { UID, permissions, website } = getState().newUser;
      const response = await updateUserPermissions(UID, permissions, website);
      dispatch(getUserDetailsAsync({}));
      dispatch(setModalOpen({}));
      dispatch(clearState({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "newUser/deleteUser",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectUser, website } = getState().newUser;
      const response = await deleteUser(selectUser, website);
      dispatch(getUserProfileAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const newUserSlice = createSlice({
  name: "newUser",
  initialState,
  reducers: {
    setNewUserEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setNewUserPassword: (state, action) => {
      state.password = action.payload.password;
    },
    setNewUserRole: (state, action) => {
      state.role = action.payload.role;
    },
    setSelectedUser: (state, action) => {
      state.UID = action.payload.value;
    },
    setSelectedEmail: (state, action) => {
      state.selectUser = action.payload.value;
    },
    setModalOpen: (state, action) => {
      state.modalOpen = !state.modalOpen;
    },
    setPermissions: (state, action) => {
      state.permissions = [...state.permissions, action.payload.value];
    },
    setUserPermission:(state, action) =>{
      action.payload.value.forEach((item)=>{
        state.permissions = [...state.permissions, item]
      })
    },
    removePermission: (state, action) => {
      let index = state.permissions.indexOf(action.payload.value);
      if (index > -1) {
        state.permissions.splice(index, 1);
      }
    },
    clearState: (state) => {
      state.email = "";
      state.password = "";
      state.permissions = [];
      state.UID = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignRoleAsync.pending, (state, action) => {
        state.Buttonloading = true;
      })
      .addCase(assignRoleAsync.fulfilled, (state, action) => {
        state.Buttonloading = false;
      })
      .addCase(assignRoleAsync.rejected, (state, action) => {
        state.Buttonloading = false;
      })

      .addCase(getUserProfileAsync.pending, (state, action) => {
        state.loading = true;
        state.userCollection = [];
      })
      .addCase(getUserProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((user) => {
          state.userCollection = [...state.userCollection, user].sort((a, b) =>
            a.firstName?.localeCompare(b.firstName)
          );
        });
      })
      .addCase(getUserProfileAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setNewUserEmail,
  setNewUserPassword,
  setNewUserRole,
  setSelectedUser,
  setModalOpen,
  setPermissions,
  setUserPermission,
  removePermission,
  setSelectedEmail,
  clearState,
} = newUserSlice.actions;
export const newUserState = (state) => state.newUser;
export default newUserSlice.reducer;
