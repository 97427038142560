import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/config";

export function uploadFile(title,file, fileName, fileType, year, website) {
  const uploadFileAPI = httpsCallable(functions, "uploadPDF");
  return uploadFileAPI({title, file, fileName, fileType, year, website });
}

export function createFolderAPI(folderName, year, website) {
  const doc = httpsCallable(functions, "createFolder");
  return doc({ folderName, year, website });
}

export function getCreateFolderAPI(website) {
  const doc = httpsCallable(functions, "getCreateFolder");
  return doc({ website });
}

export function getFolderCollectionAPI(website,year) {
  const doc = httpsCallable(functions, "getFolderCollection");
  return doc({ website ,year});
}
