import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  setNewUserEmail,
  setNewUserPassword,
  setPermissions,
  removePermission,
  newUserState,
  assignRoleAsync,
  updateUserPermissionsAsync,
} from "../reduxSlice";

export default function UserForm({ handleClose }) {
  const dispatch = useDispatch();
  const { email, password, permissions, Buttonloading, loading, UID } =
    useSelector(newUserState);
  const [error, setError] = React.useState(false);
  //password input start-----
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //password input end-----
  const handleChange = (e) => {
    if (e.target.checked) {
      dispatch(setPermissions({ value: e.target.value }));
    } else {
      dispatch(removePermission({ value: e.target.value }));
    }
  };
  const handleSubmit = () => {
    if (email && password && permissions) {
      dispatch(assignRoleAsync({}));
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleUpdate = () => {
    dispatch(updateUserPermissionsAsync({}));
  };

  return (
    <>
      <Grid component="form" container gap={2}>
        <Grid item xs={12} className="d-flex justify-content-end py-0">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className=" py-0">
          <Typography variant="h6" component="div" className="mb-3 text-center">
            {UID ? "Update Permissions" : " Add User"}
          </Typography>
        </Grid>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error" className="mb-2">
              Please fill out all required fields
            </Alert>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} sx={{ display: UID ? "none" : "flex" }}>
          <TextField
            placeholder="User Name"
            label="Email"
            id="userName"
            name="userName"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              dispatch(setNewUserEmail({ email: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: UID ? "none" : "flex" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                dispatch(setNewUserPassword({ password: e.target.value }));
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className="p-0 ">
          <FormLabel>Permissions</FormLabel>
          <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="News & Events"
                  defaultChecked={permissions?.includes("News & Events") ? true : false}
                />
              }
              label="News & Events"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Pages"
                  defaultChecked={permissions?.includes("Pages") ? true : false}
                />
              }
              label="Pages"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  label="Visitors"
                  defaultChecked={
                    permissions?.includes("Visitors") ? true : false
                  }
                />
              }
              label="Visitors"
            />
              <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Monthly Magazine"
                  defaultChecked={
                    permissions?.includes("Monthly Magazine") ? true : false
                  }
                />
              }
              label="Monthly Magazine"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Gallery"
                  defaultChecked={
                    permissions?.includes("Gallery") ? true : false
                  }
                />
              }
              label="Gallery"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Testimony"
                  defaultChecked={
                    permissions?.includes("Testimony") ? true : false
                  }
                />
              }
              label="Testimony"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Announcement"
                  defaultChecked={
                    permissions?.includes("Announcement") ? true : false
                  }
                />
              }
              label="Announcement"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Youtube Embedded"
                  defaultChecked={
                    permissions?.includes("Youtube Embedded") ? true : false
                  }
                />
              }
              label="Youtube Embedded"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Daily Quote"
                  defaultChecked={
                    permissions?.includes("Daily Quote") ? true : false
                  }
                />
              }
              label="Daily Quote"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Devotion Timing"
                  defaultChecked={
                    permissions?.includes("Devotion Timing") ? true : false
                  }
                />
              }
              label="Devotion Timing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  value="Video Gallery"
                  defaultChecked={
                    permissions?.includes("Video Gallery") ? true : false
                  }
                />
              }
              label="Video Gallery"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-end px-0">
          {Buttonloading ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : UID ? (
            <Button
              variant="contained"
              type="button"
              onClick={handleUpdate}
              startIcon={<SaveIcon />}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              type="button"
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              Add User
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
