import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  Alert
} from "@mui/material";
import {
  setDailyQuote,
  setReference,
  createDailyQuoteAsync,
  dailyQuoteState,
  getDailyQuoteAsync,
  clearState
} from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";

export default function DailyQuote() {
  const dispatch = useDispatch();
  const { dailyQuote, reference } = useSelector(dailyQuoteState);
  const [error, setError] = React.useState(false)

  React.useEffect(()=>{
    dispatch(getDailyQuoteAsync({}))
    return()=>{
      dispatch(clearState({}))
    }
  },[dispatch])

  const handleClick = (e) => {
    e.preventDefault();
   if(reference && dailyQuote){
    dispatch(createDailyQuoteAsync({}));
    setError(false)
   }else{
    setError(true)
   }
  };
  return (
    <>
      <Container
        className="p-md-5"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 0,
            },
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component="h5">
            {"Daily Quote"}
          </Typography>
        </Box>
        <Grid container component="form" onSubmit={handleClick} spacing={2}>
          <Grid item xs={12}>
          {error ? (
            <Alert severity="error" className="mb-2">Please fill out all required fields</Alert>
          ) : (
            ""
          )}
            <TextField
              id="reference"
              label="Reference"
              fullWidth
              value={reference ? reference : ""}
              onChange={(e) => {
                dispatch(setReference({ value: e.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="dailyquote"
              label="Daily Quote"
              multiline
              rows={4}
              value={dailyQuote ? dailyQuote : ""}
              fullWidth
              onChange={(e) => {
                dispatch(setDailyQuote({ value: e.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
