import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTestimonial,
  getTestimonial,
  deleteTestimonial,
  updateTestimonial,
} from "./reduxAPI";

const initialState = {
  title: "",
  name: "",
  id: "",
  content: "",
  language: "",
  testimonialCollection: [],
  website: "stanthony",
};

export const createTestimonialAsync = createAsyncThunk(
  "testimonial/createTestimonial",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { title, name, content, website, language } =
        getState().testimonial;

      const response = await createTestimonial(
        title,
        name,
        content,
        website,
        language
      );
      dispatch(clearState({}));
      dispatch(getTestimonialAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getTestimonialAsync = createAsyncThunk(
  "testimonial/getTestimonial",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { website } = getState().testimonial;
      const sessionToken = window.localStorage.getItem("sessionToken");
      const response = await getTestimonial(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteTestimonialAsync = createAsyncThunk(
  "testimonial/deleteTestimonial",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, website } = getState().testimonial;

      const response = await deleteTestimonial(id, website);
      dispatch(getTestimonialAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateTestimonialAsync = createAsyncThunk(
  "testimonial/updateTestimonial",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, title, name, content, website, language } =
        getState().testimonial;

      const response = await updateTestimonial(
        id,
        title,
        name,
        content,
        website,
        language
      );
      dispatch(clearState({}));
      dispatch(getTestimonialAsync({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload.title;
    },
    setName: (state, action) => {
      state.name = action.payload.name;
    },
    setContent: (state, action) => {
      state.content = action.payload.content;
    },
    setId: (state, action) => {
      state.id = action.payload.value;
    },
    setLanguage: (state, action) => {
      state.language = action.payload.value;
    },
    clearState: (state) => {
      state.title = "";
      state.name = "";
      state.content = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTestimonialAsync.pending, (state, action) => {
        state.loading = true;
        state.id = "";
      })
      .addCase(createTestimonialAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createTestimonialAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getTestimonialAsync.pending, (state, action) => {
        state.loading = true;
        state.testimonialCollection = [];
      })
      .addCase(getTestimonialAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.data.forEach((item) => {
          state.testimonialCollection = [
            ...state.testimonialCollection,
            item,
          ].sort(function (a, b) {
            return new Date(b.create) - new Date(a.create);
          });
        });
      })
      .addCase(getTestimonialAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteTestimonialAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteTestimonialAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.id = "";
      })
      .addCase(deleteTestimonialAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateTestimonialAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateTestimonialAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateTestimonialAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setTitle, setName, setContent, setId, setLanguage, clearState } =
  testimonialSlice.actions;
export const testimonialState = (state) => state.testimonial;
export default testimonialSlice.reducer;
