import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFolderAPI,
  getCreateFolderAPI,
  getFolderCollectionAPI,
  uploadFile,
} from "./reduxAPI";

const initialState = {
  loading: false,
  showPdfList: false,
  selectedHeading: "",
  folderName: "",
  year: "",
  date: "",
  title: "",
  website: "stanthony",
  collection: "",
  folderCollection: "",
};

export const uploadFileAsync = createAsyncThunk(
  "littleVisitor/uploadFile",
  async (
    { file, fileName, fileType },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { title, year, website } = getState().littleVisitor;
      const response = await uploadFile(
        title,
        file,
        fileName,
        fileType,
        year,
        website
      );
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const createFolderAsync = createAsyncThunk(
  "littleVisitor/createFolderAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { folderName, year, website } = getState().littleVisitor;
      const response = await createFolderAPI(folderName, year, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getCreateFolderAsync = createAsyncThunk(
  "littleVisitor/getCreateFolderAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { website } = getState().littleVisitor;
      const response = await getCreateFolderAPI(website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getFolderCollectionAsync = createAsyncThunk(
  "littleVisitor/getFolderCollectionAPI",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { website, year } = getState().littleVisitor;
      const response = await getFolderCollectionAPI(website, year);
      console.log(response);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const LittleVisitorSlice = createSlice({
  name: "littleVisitor",
  initialState,
  reducers: {
    setShowPdfList: (state, action) => {
      state.showPdfList = action.payload.value;
    },
    setSelectedHeading: (state, action) => {
      state.selectedHeading = action.payload.value;
    },
    setFolderName: (state, action) => {
      state.folderName = action.payload.value;
    },
    setYear: (state, action) => {
      state.year = action.payload.value;
    },
    setDate: (state, action) => {
      state.date = action.payload.value;
    },
    setTitle: (state, action) => {
      state.title = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreateFolderAsync.pending, (state, action) => {
        state.loading = true;
        state.collection = "";
      })
      .addCase(getCreateFolderAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.collection = action.payload.data;
        action.payload.data.forEach((data) => {
          state.collection = [...state.collection, data].sort(function (a, b) {
            return new Date(b.year) - new Date(a.year);
          });
        });
      })
      .addCase(getCreateFolderAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getFolderCollectionAsync.pending, (state, action) => {
        state.loading = true;
        state.folderCollection = "";
      })
      .addCase(getFolderCollectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        // state.folderCollection = action.payload.data;
        action.payload.data.forEach((data) => {
          state.folderCollection = [...state.folderCollection, data].sort(
            function (a, b) {
              return new Date(b.uploadDate) - new Date(a.uploadDate);
            }
          );
        });
      })
      .addCase(getFolderCollectionAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setShowPdfList,
  setSelectedHeading,
  setFolderName,
  setYear,
  setDate,
  setTitle,
  setSelectedFolder,
} = LittleVisitorSlice.actions;
export const littlevisitorState = (state) => state.littleVisitor;
export default LittleVisitorSlice.reducer;
