import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createDailyQuote, getDailyQuote } from "./reduxAPI";

const initialState = {
  dailyQuote: "",
  reference: "",
  website: "stanthony",
};

export const createDailyQuoteAsync = createAsyncThunk(
  "dailyQuote/createDailyQuote",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { dailyQuote, reference, website } = getState().dailyQuote;
      const response = await createDailyQuote(dailyQuote, reference, website);
      dispatch(clearState({}));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getDailyQuoteAsync = createAsyncThunk(
  "dailyQuote/getDailyQuote",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const sessionToken = window.localStorage.getItem("sessionToken");
      const { website } = getState().dailyQuote;
      const response = await getDailyQuote(sessionToken, website);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const dailyQuoteSlice = createSlice({
  name: "dailyQuote",
  initialState,
  reducers: {
    setDailyQuote: (state, action) => {
      state.dailyQuote = action.payload.value;
    },
    setReference: (state, action) => {
      state.reference = action.payload.value;
    },
    clearState: (state) => {
      state.dailyQuote = "";
      state.reference = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDailyQuoteAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createDailyQuoteAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.dailyQuote = "";
        state.reference = "";
      })
      .addCase(createDailyQuoteAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDailyQuoteAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDailyQuoteAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getDailyQuoteAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setDailyQuote, setReference, clearState } =
  dailyQuoteSlice.actions;
export const dailyQuoteState = (state) => state.dailyQuote;
export default dailyQuoteSlice.reducer;
